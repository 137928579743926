table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
  text-align: center;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

ol {
  margin: 0;
}
