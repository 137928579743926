.fraud_contacts_table {

	/* .table-container {
		width: 100%;
		overflow-x: auto;
	} */

	.fraud_contacts {
		width: 100%;
		border-collapse: collapse;
		table-layout: fixed;
	}

	th,
	td {
		padding: 8px;
		border: 1px solid #ddd;
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	th {
		background-color: #f4f4f4;
		text-align: center;
	}

	/* tr {
		display: flex;
	}

	tbody {
		display: block;
	}

	thead {
		display: block;
	} */

	/* .header,
	.body {
		display: flex;
	}

	.header>div,
	.body>div {
		flex: 1;
		padding: 8px;
		border-bottom: 1px solid #ccc;
	} */

	.centered {
		text-align: center;
	}

	td:nth-child(1) { /* Type */
		min-width: 80px;
		width: 80px;
		max-width: 80px;
	}
	td:nth-child(2) { /* Value */
		min-width: 250px;
		width: 250px;
		max-width: 250px;
	}
	td:nth-child(3) { /* Note */
		min-width: 500px;
		width: 500px;
		max-width: 500px;
	}
	td:nth-child(4) { /* Source */
		min-width: 200px;
		width: 200px;
		max-width: 200px;
	}
	td:nth-child(5) { /* Created */
		min-width: 200px;
		width: 200px;
		max-width: 200px;
	}

}