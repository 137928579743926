body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.carrierServicesTickets {
   margin: 0px;
   padding: 0px;
   border: 0px;
   outline: 0px;
   vertical-align: baseline;
   text-align: left;
   cursor: auto;
}

table.carrierServicesTickets {
   border-spacing: 0px;
   min-width: 1673px;
   width: 1673px;
   left: 40px;
   border-collapse: collapse;
   position: relative;
   max-width: 100%;
}

td.carrierServicesTickets, th.carrierServicesTickets {
   border-spacing: 0px;
   border-collapse: collapse;
}

th.carrierServicesTickets {
   min-width: 15px;
   width: 15px;
   border-bottom: 1px solid rgb(216, 220, 222);
   background: transparent none repeat scroll 0% 0%;
   height: 40px;
   outline: currentcolor none 0px;
   border: 0px none;
   position: relative;
   font-size: 14px;
   color: rgb(47, 57, 65);
   vertical-align: middle;
   padding: 0px;
   white-space: nowrap;
   box-sizing: border-box;
   font-weight: 600;
   border-spacing: 0px;
   border-collapse: collapse;
   cursor: auto;
}

td.carrierServicesTickets {
   border-width: 0px 0px 1px;
   border-style: solid;
   border-color: rgb(233, 235, 237);
   max-width: 400px;
   text-overflow: ellipsis;
   background: transparent none repeat scroll 0% 0%;
   padding: 0px 2px 0px 12px;
   height: 40px;
   outline: currentcolor none 0px;
   font-size: 14px;
   color: rgb(47, 57, 65);
   vertical-align: middle;

}



/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #b50d00;
  color: white;
}

