.fraudContactRoot {
	
	div.tableRoot {
		display: inline-block;
		overflow-x: auto;
	}

	div.filterBar {
		margin: 13px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
	}

	/* .fraud_contacts {
		width: 100%;
		border-collapse: collapse;
	} */

	/* .fraud_contacts .header > div, .fraud_contacts .body > div {
		flex: 1;
		padding: 8px;
		border-bottom: 1px solid #ccc;
	} */

	div.filterGroup {
		display: inline-block;
		margin-left: 10px;
		border: 1px solid #646464;
		padding: 5px;
	}

	input.searchBox {
		margin-left: 10px;
		width: 250px;
	}

	label {
		margin-right: 10px;
	}

	select {
		margin-right: 10px;
	}

	div.statistics {
		padding: 0px 10%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		border: 1px solid #646464;
	}

	div.statistics p {
		display: inline;
		margin-right: 10px;
		text-align: center;
	}
}

